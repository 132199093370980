<template>
<div>
    <main class="form-signin w-100 m-auto">
        <form action="Checkout" @submit.prevent="tryLogin" id="login-form">
            <h1 class="h3 mb-3" id="page-header-text">{{ $t('loginPage.loginHeader') }}</h1>

            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="mail" v-model="formData.input" @input="checkInput" placeholder="name@example.com" required>
                <span v-if="isInputValid == false" class="mt-3 fw-normal d-flex text-start text-danger px-3"><span v-if="validationText && validationText != ''">{{ validationText }}</span></span>
                <label for="mail">{{ $t('loginPage.inputLabel') }}</label>
            </div>
            <div class="form-floating mb-3">
                <input :type="showPassword ? 'text' : 'password'" class="form-control" id="password" v-model="formData.password" placeholder="Şifre" required>
                <span @click="togglePassword" toggle="#password-field" class="fa fa-fw field-icon toggle-password base-password text-zerduz" :class="{ 'fa-eye': !showPassword, 'fa-eye-slash active': showPassword }"></span>
                <label for="password">{{ $t('loginPage.passwordLabel') }}</label>
            </div>

            <div class="checkbox mb-3" id="remember-me-box">
                <label>
                    <input type="checkbox" class="form-check-input" value="remember-me" id="remember-me" v-model="formData.rememberme"> {{ $t('loginPage.rememberMe') }}
                </label>
            </div>

            <button class="w-100 btn btn-lg btn-primary" type="submit">
                <span class="login-button-text">{{ $t('loginPage.buttonText') }}</span>
                <div class="btn-loading">
                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span class="visually-hidden" role="status">{{ $t('loadingText') }}</span>
                </div>
            </button>
            <hr class="my-4">
            <div class="vstack">
                <small class="text-body-secondary">
                    <router-link to="Register" class="link-underline link-underline-opacity-0">{{ $t('loginPage.registerLink') }}</router-link>
                </small>
                <small class="text-body-secondary">
                    <router-link to="Resetpass" class="link-underline link-underline-opacity-0">{{ $t('loginPage.forgotPasswordLink') }}</router-link>
                </small>
            </div>
        </form>
    </main>
</div>
</template>

<script>
import {
    login,
    updateCart,
    addToCart
} from "@/helpers/api";
import {
    alert
} from "@/helpers/funcs";
import $ from 'jquery';
import {
    isPossiblePhoneNumber
} from 'libphonenumber-js';

export default {
    name: "LoginV",
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            formData: {
                input: '',
                mail: '',
                phone: '',
                password: '',
                rememberme: false
            },
            showPassword: false,
            redirectUrl: null,
            isInputValid: null,
            validationText: '',
        }
    },
    methods: {
        checkInput() {
            if (this.isEmail(this.formData.input)) {
                this.formData.mail = this.formData.input;
                this.formData.phone = '';

                this.isInputValid = true;
                this.validationText = "";
            } else if (this.isPhoneNumber(this.formData.input)) {
                this.formData.phone = this.formData.input;
                this.formData.mail = '';

                this.isInputValid = true;
                this.validationText = "";
            } else {
                if (this.isPhoneNumber("+" + this.formData.input)) {
                    this.formData.phone = "+" + this.formData.input;
                    this.formData.mail = '';

                    this.isInputValid = true;
                    this.validationText = "";
                } else {
                    this.isInputValid = false;
                    this.validationText = this.$t('errors.login.inputCheckInputError');
                }

            }
        },
        isEmail(value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
        },
        isPhoneNumber(value) {
            var validation = isPossiblePhoneNumber(value);
            return validation;
        },
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        async tryLogin() {
            $('.login-button-text').fadeOut(async () => {
                if (this.isInputValid) {
                    var phone = this.formData.phone;
                    if (phone.length > 0) {
                        if (phone.charAt(0) === '0') {
                            phone = phone.slice(1);
                        }
                        if (phone.charAt(0) === '+') {
                            phone = phone.slice(1);
                        }
                        phone = phone.trim().replace(/\s/g, '');
                    }
                    $('.btn-loading').fadeIn();
                    if ((this.formData.mail && this.formData.mail != '') || (phone && phone != '')) {
                        if (this.formData.password && this.formData.password != '') {
                            const {
                                success,
                                message,
                                token,
                                userId,
                                email,
                                first_name,
                                last_name,
                                zid,
                                cart
                            } = await login(this.formData.mail, phone, this.formData.password);
                            if (success) {
                                window.localStorage.setItem('token', token);
                                window.localStorage.setItem('userId', userId);
                                window.localStorage.setItem('email', email);
                                if (zid && zid != null && zid != '') {
                                    window.localStorage.setItem('zid', zid);
                                    this.$store.dispatch('changeZid', {
                                        zid
                                    });
                                }
                                var expirationDate = new Date();
                                if (this.formData.rememberme) {
                                    expirationDate.setDate(expirationDate.getDate() + 30);
                                } else {
                                    expirationDate.setDate(expirationDate.getDate() + 2);
                                }

                                const cartData = JSON.parse(localStorage.getItem('cart') || '[]');
                                if (cartData.length === 0 && cart.length > 0) {
                                    $('.btn-loading').fadeOut(function () {
                                        $('.login-button-text').fadeIn();
                                    });
                                    if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                                        var newCartItem = {
                                            productId: 1,
                                            cartQuantity: cart[0].quantity,
                                            payorder_id: cart[0].payorder_id
                                        };
                                        cartData.push(newCartItem);
                                        this.$store.dispatch('changeCartItem', {
                                            cart: cartData
                                        });
                                        window.localStorage.setItem('cart', JSON.stringify(cartData));
                                    }
                                } else if (cartData.length > 0 && (cartData[0].cartQuantity == 0 || cartData[0].cartQuantity == null || cartData[0].cartQuantity == "")) {
                                    $('.btn-loading').fadeOut(function () {
                                        $('.login-button-text').fadeIn();
                                    });
                                    if (cart.length > 0) {
                                        if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                                            cartData[0].cartQuantity = cart[0].quantity;
                                            cartData[0].payorder_id = cart[0].payorder_id;
                                            this.$store.dispatch('changeCartItem', {
                                                cart: cartData
                                            });
                                            window.localStorage.setItem('cart', JSON.stringify(cartData));
                                        }
                                    }
                                } else if (cartData.length > 0 && cartData[0].cartQuantity > 0) {
                                    if (cart) {
                                        if (cart.length > 0) {
                                            if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                                                if (cartData[0].cartQuantity > cart[0].quantity) {
                                                    var operation = true;
                                                    if ((parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity) > 0)) {
                                                        operation = true;
                                                    } else if ((parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity) < 0)) {
                                                        operation = false;
                                                    }
                                                    var updateCartQuantity = parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity);
                                                    const {
                                                        success: cartSuccess
                                                    } = await updateCart(token, 1, updateCartQuantity, cart[0].payorder_id, operation);
                                                    if (cartSuccess) {
                                                        cartData[0].payorder_id = cart[0].payorder_id;
                                                        window.localStorage.setItem('cart', JSON.stringify(cartData));
                                                    }
                                                } else {
                                                    cartData[0].cartQuantity = cart[0].quantity;
                                                    cartData[0].payorder_id = cart[0].payorder_id;
                                                    this.$store.dispatch('changeCartItem', {
                                                        cart: cartData
                                                    });
                                                    window.localStorage.setItem('cart', JSON.stringify(cartData));
                                                }
                                            }
                                        } else {
                                            await addToCart(token, 1, parseInt(cartData[0].cartQuantity), cartData[0].payorder_id, true);
                                        }
                                    }
                                }
                                $('.btn-loading').fadeOut(function () {
                                    $('.login-button-text').fadeIn();
                                });
                                window.localStorage.setItem('expirationDate', expirationDate.getTime());
                                var userName = success ? (first_name && last_name ? `${first_name} ${last_name}` : first_name) : '';
                                this.$store.dispatch('login', {
                                    token: token,
                                    userId: userId,
                                    username: userName,
                                    expirationdate: expirationDate.getTime()
                                });
                                alert("#remember-me-box", message, "success", "mt-3");
                                setTimeout(function (scope) {
                                    if (String(scope.$route.path).toLowerCase() === '/login') {
                                        if (scope.redirectUrl != null) {
                                            scope.$router.push(scope.redirectUrl);
                                        } else {
                                            scope.$router.push('/Account/Orders');
                                        }
                                    }
                                }, 2000, this);
                            } else {
                                $('.btn-loading').fadeOut(200, function () {
                                    $('.login-button-text').fadeIn();
                                });
                                alert("#remember-me-box", message, "danger", "mt-2");
                            }
                        } else {
                            $('.btn-loading').fadeOut(function () {
                                $('.login-button-text').fadeIn();
                            });
                            alert("#remember-me-box", this.$t('errors.login.passwordCantBeEmpty'), "danger", "mt-2");
                        }
                    } else {
                        $('.btn-loading').fadeOut(function () {
                            $('.login-button-text').fadeIn();
                        });
                        alert("#remember-me-box", this.$t('errors.login.emailCantBeEmpty'), "danger", "mt-2");
                    }
                } else {
                    $('.btn-loading').fadeOut(function () {
                        $('.login-button-text').fadeIn();
                    });
                    alert("#remember-me-box", this.$t('errors.login.inputCheckInputError'), "danger", "mt-2");
                }
            });
        },
    },
    mounted() {
        if (this.$route.query.r && (this.$route.query.r != null || this.$route.query.r != '')) {
            this.redirectUrl = this.$route.query.r;
        }
    },
};
</script>

  <style scoped src="../assets/css/sign-in.css"/>
