<template>
<div>
    <div class="px-4 my-5 text-center">
        <h1 class="text-center mt-5 p-1" id="page-header-text">
            {{ $store.state.userName 
                ? $t('accountPages.welcome', { name: $store.state.userName.split(' ')[0] }) 
                : $t('accountPages.header') }}
        </h1>
    </div>

    <div class="container py-3">
        <div class="row">
            <accountPageSidemenu />
            <div class="col-lg-9 col-md-8 col-sm-12" id="account-settings-tab">
                <div class="border my-2 rounded-2 shadow-sm">
                    <div class="container">
                        <div class="row p-3">
                            <ul class="nav nav-pills nav-fill gap-2 p-1 small bg-primary rounded-5 shadow-sm" id="settingsNav" role="tablist" style="--bs-nav-link-color: var(--bs-white); --bs-nav-pills-link-active-color: var(--bs-primary); --bs-nav-pills-link-active-bg: var(--bs-white);">
                                <!-- <div class="col">
                                    <li class="nav-item" role="presentation">
                                        <router-link to="/Account/Zerduz/Currency" active-class="" class="nav-link text-white text-opacity-50 rounded-5" id="currency-tab" type="button">{{ $t('accountPages.zerduzSettings.menu[0].title') }}</router-link>
                                    </li>
                                </div> -->
                                <div class="col">
                                    <li class="nav-item" role="presentation">
                                        <router-link to="/Account/Zerduz/Announcements" active-class="" class="nav-link active rounded-5" id="announcements-tab" type="button">{{ $t('accountPages.zerduzSettings.menu[1].title') }}</router-link>
                                    </li>
                                </div>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-announcements" role="tabpanel" aria-labelledby="pills-announcements" tabindex="0">
                                    <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                                        <h3 class="mt-4 mb-3 mb-md-0">{{ $t('accountPages.zerduzSettings.announcements.title') }}</h3>
                                        <button class="btn btn-primary w-md-auto" @click="showAddAnnouncementModal">{{ $t('accountPages.zerduzSettings.announcements.addButton') }}</button>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table table-hover table-borderless mx-auto text-center mt-3">
                                            <thead class="border-bottom text-gray opacity-75">
                                                <tr>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.table.image') }}</th>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.table.title') }}</th>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.table.description') }}</th>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.table.createdAt') }}</th>
                                                    <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.table.hasOptions') }}</th>
                                                    <th scope="col" class="fw-normal"></th>
                                                </tr>
                                            </thead>
                                            <tbody class="align-middle">
                                                <tr v-if="announcementsLoading">
                                                    <td colspan="6" class="text-center">
                                                        <div class="spinner-border text-zerduz mt-1" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-else-if="announcements.length === 0">
                                                    <td colspan="6" class="text-center">
                                                        <p class="text-gray opacity-75 mt-2">{{ $t('accountPages.zerduzSettings.announcements.noAnnouncements') }}</p>
                                                    </td>
                                                </tr>
                                                <tr v-for="announcement in paginatedAnnouncements" :key="announcement.id">
                                                    <td>
                                                        <img :src="imageUrl + announcement.image + '-1'" class="img-thumbnail" width="102" height="auto" alt="">
                                                    </td>
                                                    <td>
                                                        {{ announcement.title }}
                                                    </td>
                                                    <td>
                                                        {{ announcement.description.length > 60 ? announcement.description.substring(0, 50) + '...' : announcement.description }}
                                                    </td>
                                                    <td>
                                                        {{ formatDate(announcement.created_at) }}
                                                    </td>
                                                    <td>
                                                        <i class="fa-solid fa-check text-success fa-xl" v-if="announcement.images_count && announcement.images_count > 1"></i>
                                                        <i class="fa-solid fa-xmark text-danger fa-xl" v-else></i>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-outline-info border-0 btn-sm" @click="showResultsModal(announcement)"><i class="fa-solid fa-eye fa-xs"></i></button>
                                                        <button class="btn btn-outline-dark border-0 btn-sm" @click="showRepeatModal(announcement)"><i class="fa-solid fa-repeat fa-xs"></i></button>
                                                        <button class="btn btn-outline-danger border-0 btn-sm" @click="deleteAnnouncement(announcement.image, announcement.title)"><i class="fa-solid fa-trash fa-xs"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div id="alertbox"></div>
                                    </div>

                                    <nav v-if="announcements.length > announcementsPerPage" class="mt-4">
                                        <ul class="pagination pagination-sm justify-content-center gap-1">
                                            <li class="page-item" :class="{ disabled: currentAnnouncementPage === 1 }">
                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                   href="#" 
                                                   @click.prevent="changeAnnouncementPage(currentAnnouncementPage - 1)">
                                                    <i class="fa-solid fa-chevron-left fa-xs"></i>
                                                </a>
                                            </li>
                                            
                                            <li v-if="currentAnnouncementPage > 2" class="page-item">
                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                   href="#" 
                                                   @click.prevent="changeAnnouncementPage(1)">1</a>
                                            </li>
                                            
                                            <li v-if="currentAnnouncementPage > 3" class="page-item disabled">
                                                <span class="page-link border-0 bg-transparent text-secondary px-2">...</span>
                                            </li>
                                            
                                            <li v-if="currentAnnouncementPage > 1" class="page-item">
                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                   href="#" 
                                                   @click.prevent="changeAnnouncementPage(currentAnnouncementPage - 1)">
                                                    {{ currentAnnouncementPage - 1 }}
                                                </a>
                                            </li>
                                            
                                            <li class="page-item active">
                                                <a class="page-link rounded-2 border-0 px-2" 
                                                   href="#" 
                                                   @click.prevent="changeAnnouncementPage(currentAnnouncementPage)">
                                                    {{ currentAnnouncementPage }}
                                                </a>
                                            </li>
                                            
                                            <li v-if="currentAnnouncementPage < totalAnnouncementPages" class="page-item">
                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                   href="#" 
                                                   @click.prevent="changeAnnouncementPage(currentAnnouncementPage + 1)">
                                                    {{ currentAnnouncementPage + 1 }}
                                                </a>
                                            </li>
                                            
                                            <li v-if="currentAnnouncementPage < totalAnnouncementPages - 2" class="page-item disabled">
                                                <span class="page-link border-0 bg-transparent text-secondary px-2">...</span>
                                            </li>
                                            
                                            <li v-if="currentAnnouncementPage < totalAnnouncementPages - 1" class="page-item">
                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                   href="#" 
                                                   @click.prevent="changeAnnouncementPage(totalAnnouncementPages)">
                                                    {{ totalAnnouncementPages }}
                                                </a>
                                            </li>
                                            
                                            <li class="page-item" :class="{ disabled: currentAnnouncementPage === totalAnnouncementPages }">
                                                <a class="page-link rounded-2 border-0 text-secondary px-2" 
                                                   href="#" 
                                                   @click.prevent="changeAnnouncementPage(currentAnnouncementPage + 1)">
                                                    <i class="fa-solid fa-chevron-right fa-xs"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-lg fade" id="addAnnouncementModal" role="dialog" tabindex="-1" aria-labelledby="addAnnouncementModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5 d-flex align-items-center gap-2" id="addAnnouncementModalLabel">{{ $t('accountPages.zerduzSettings.announcements.modal.add.title') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="title" v-model="addAnnouncementData.title" placeholder=" " required>
                            <label for="title">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.title') }}</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" id="description" v-model="addAnnouncementData.description" placeholder="name@example.com">
                            <label for="description">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.description') }}</label>
                        </div>
                        <div class="mb-3">
                            <label for="formFile" class="form-label">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.image') }}</label>
                            <input class="form-control" type="file" id="formFile" @change="handleAddImage" accept="image/*">
                            <label class="form-label mt-3" v-if="addAnnouncementData.image">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.selectedImage') }}</label>
                            <div v-if="addAnnouncementData.image" class="d-flex align-items-center gap-2">
                                <img :src="addAnnouncementData.image" class="img-thumbnail" width="196" height="auto" alt="" />
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="addAnnouncementUsers" class="form-label">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.users') }}</label>
                            <Multiselect v-model="addAnnouncementData.users" id="addAnnouncementUsers" :options="userOptions" @clear="userOptionsCleared('makepoll')" @select="userOptionsSelected" @deselect="userOptionsDeselected('makepoll')" track-by="label" label="label" :multiple="true" :searchable="true" :close-on-select="false" :hide-selected="true" mode="tags">
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag is-country text-wrap" :class="{ 'is-disabled': disabled }">
                                        {{ option.label }}
                                        <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                    </div>
                                </template>
                            </Multiselect>
                        </div>
                        <div class="mb-3">
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" v-model="addAnnouncementData.hasOptions" id="hasOptions">
                                <label class="form-check-label" for="hasOptions">
                                    {{ $t('accountPages.zerduzSettings.announcements.modal.add.form.hasOptions') }}
                                </label>
                            </div>
                            
                            <transition name="fade">
                                <div v-if="addAnnouncementData.hasOptions" class="mt-4">
                                    <label class="form-label">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.options') }}</label>
                                    <div class="row g-3">
                                        <div class="col-md-6" v-for="(option, index) in 4" :key="index">
                                            <div class="card">
                                                <div class="card-body">
                                                    <h6 class="card-title">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.option', { number: index + 1 }) }}</h6>
                                                    <input class="form-control mb-2" type="file" :id="'optionImage' + index" 
                                                        @change="handleOptionImage($event, index)" accept="image/*">
                                                    <div class="form-floating mb-2">
                                                        <input type="text" class="form-control" 
                                                               :id="'optionDescription' + index"
                                                               v-model="addAnnouncementData.optionDescriptions[index]" 
                                                               :placeholder="$t('accountPages.zerduzSettings.announcements.modal.add.form.optionDescription')">
                                                        <label :for="'optionDescription' + index">{{ $t('accountPages.zerduzSettings.announcements.modal.add.form.optionDescription') }}</label>
                                                    </div>
                                                    <div v-if="addAnnouncementData.optionImages[index]" class="mt-2">
                                                        <img :src="addAnnouncementData.optionImages[index]" 
                                                            class="img-thumbnail" width="100" height="auto" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div id="modal-alertbox"></div>
                </div>

                <div class="modal-footer border-0 gap-2">
                    <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <button type="button" class="btn btn-light btn-block" data-bs-dismiss="modal" id="closeAddAnnouncementModal">{{ $t('accountPages.zerduzSettings.announcements.close') }}</button>
                    </div>
                    <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <button type="button" class="btn btn-primary btn-block" @click="addAnnouncement" :disabled="addStatus === 'loading'">
                            <span v-if="addStatus === 'loading'" class="spinner-border spinner-border-sm me-2" role="status">
                                <span class="visually-hidden">Yükleniyor...</span>
                            </span>
                            {{ $t('accountPages.zerduzSettings.announcements.add') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteModal" role="dialog" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5 d-flex align-items-center gap-2" id="deleteModalLabel">{{ $t('accountPages.zerduzSettings.announcements.modal.delete.title') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h6 class="card-title mb-3">{{ $t('accountPages.zerduzSettings.announcements.modal.delete.confirmation', { title: deleteAnnouncementData.title }) }}</h6>
                </div>
                <div class="modal-footer gap-2">
                    <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <button type="button" class="btn btn-light btn-block" data-bs-dismiss="modal" id="closeDeleteModal">{{ $t('accountPages.address.list.addressElements.closeBtn') }}</button>
                    </div>
                    <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <button type="button" class="btn btn-danger btn-block" @click="tryToDeleteAnnouncement">{{ $t('accountPages.address.list.addressElements.deleteAddressBtn') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="resultsModal" role="dialog" tabindex="-1" aria-labelledby="resultsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5 d-flex align-items-center gap-2" id="resultsModalLabel">{{ $t('accountPages.zerduzSettings.announcements.modal.results.report.title') }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="card border-0">
                        <div class="card-body">
                            <img :src="imageUrl + resultsModalData.data.image + '-1'" class="img-fluid rounded-3 shadow mb-3 d-flex mx-auto" width="auto" height="auto" alt="">
                            <h5 class="card-title">{{ resultsModalData.data.title }}</h5>
                            <p class="card-text">{{ resultsModalData.data.description }}</p>
                            <p class="card-text"><small class="text-body-secondary">{{ $t('accountPages.zerduzSettings.announcements.modal.results.createdAt') }}: {{ formatDate(resultsModalData.data.created_at) }}</small></p>
                            
                            <div class="rounded-3 p-3 shadow-sm border border-opacity-10">
                                <h6 class="mb-3">{{ $t('accountPages.zerduzSettings.announcements.modal.results.options') }}</h6>
                                <div v-if="resultsModalData.data.images_count > 1">
                                    <div class="row g-3">
                                        <div class="col-md-6" v-for="index in 4" :key="index">
                                            <div class="card border-0 shadow-sm h-100">
                                                <div class="card-body d-flex flex-column mx-auto">
                                                    <h6 class="card-title mb-3 text-center">{{ $t('accountPages.zerduzSettings.announcements.modal.results.option', { number: index }) }}</h6>
                                                    <img :src="imageUrl + resultsModalData.data.image + '-' + (index+1)" 
                                                         class="img-fluid rounded-3 shadow mb-3" 
                                                         width="200" 
                                                         height="auto" 
                                                         :alt="$t('accountPages.zerduzSettings.announcements.modal.results.optionAlt', { number: index })">
                                                    <p v-if="resultsModalData.data.parsedDescriptions" class="text-muted small mb-3">
                                                        {{ resultsModalData.data.parsedDescriptions[['first', 'second', 'third', 'fourth'][index-1]] }}
                                                    </p>
                                                    <div class="mt-auto text-center">
                                                        <small class="text-gray opacity-75" v-if="resultsModalData.results && resultsModalData.results.length > 0">
                                                            {{ resultsModalData.results.filter(item => (item.child_image - 1) == index).length }} {{ $t('accountPages.zerduzSettings.announcements.modal.results.count') }}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="row g-3">
                                    <div class="col">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="text-center">
                                                <i class="fa-solid fa-check fa-2xl text-success"></i>
                                                <div class="mt-2">
                                                    <small class="text-gray opacity-75">{{ resultsModalData.results.filter(item => item.option == 2).length }} {{ $t('accountPages.zerduzSettings.announcements.modal.results.count') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="text-center">
                                                <i class="fa-solid fa-thumbs-up fa-2xl text-primary"></i>
                                                <div class="mt-2">
                                                    <small class="text-gray opacity-75">{{ resultsModalData.results.filter(item => item.option == 1).length }} {{ $t('accountPages.zerduzSettings.announcements.modal.results.count') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <div class="text-center">
                                                <i class="fa-solid fa-thumbs-down fa-2xl text-danger"></i>
                                                <div class="mt-2">
                                                    <small class="text-gray opacity-75">{{ resultsModalData.results.filter(item => item.option == 0).length }} {{ $t('accountPages.zerduzSettings.announcements.modal.results.count') }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="table-responsive">
                                <table class="table table-hover table-borderless mx-auto text-center mt-3">
                                    <thead class="border-bottom text-gray opacity-75">
                                        <tr>
                                            <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.modal.results.table.user') }}</th>
                                            <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.modal.results.table.email') }}</th>
                                            <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.modal.results.table.zerduzId') }}</th>
                                            <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.modal.results.table.option') }}</th>
                                            <th scope="col" class="fw-normal">{{ $t('accountPages.zerduzSettings.announcements.modal.results.table.date') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="align-middle">
                                        <tr v-for="result in paginatedResults" :key="result.user_id">
                                            <td>{{ result.user_name.replace('. ', ' ') }}</td>
                                            <td>{{ result.email }}</td>
                                            <td>{{ result.zid }}</td>
                                            <td>{{ result.child_image ? $t('accountPages.zerduzSettings.announcements.modal.results.report.options.option', { number: result.child_image - 1 }) : resultOptions.find(item => item.value == result.option)?.option || result.option }}</td>
                                            <td>{{ formatDate(result.created_at) }}</td>
                                        </tr>
                                        <tr v-if="resultsModalDataLoading">
                                            <td colspan="4" class="text-center">
                                                <div class="spinner-border text-zerduz mt-1" role="status">
                                                    <span class="visually-hidden">{{ $t('accountPages.zerduzSettings.announcements.modal.results.loading') }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-else-if="resultsModalDataLoading == false && resultsModalData.results.length == 0">
                                            <td colspan="4" class="text-center">
                                                <p class="text-gray opacity-75 mt-2">{{ $t('accountPages.zerduzSettings.announcements.modal.results.noResponse') }}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <nav v-if="resultsModalData.results.length > itemsPerPage" class="mt-4">
                                    <ul class="pagination pagination-sm justify-content-center gap-1">
                                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                            <a class="page-link rounded-2 border-0 text-secondary px-2" href="#" @click.prevent="changePage(currentPage - 1)">
                                                <i class="fa-solid fa-chevron-left fa-xs"></i>
                                            </a>
                                        </li>
                                        
                                        <li v-if="currentPage > 2" class="page-item">
                                            <a class="page-link rounded-2 border-0 text-secondary px-2" href="#" @click.prevent="changePage(1)">1</a>
                                        </li>
                                        
                                        <li v-if="currentPage > 3" class="page-item disabled">
                                            <span class="page-link border-0 bg-transparent text-secondary px-2">...</span>
                                        </li>
                                        
                                        <li v-if="currentPage > 1" class="page-item">
                                            <a class="page-link rounded-2 border-0 text-secondary px-2" href="#" @click.prevent="changePage(currentPage - 1)">
                                                {{ currentPage - 1 }}
                                            </a>
                                        </li>
                                        
                                        <li class="page-item active">
                                            <a class="page-link rounded-2 border-0 px-2" href="#" @click.prevent="changePage(currentPage)">
                                                {{ currentPage }}
                                            </a>
                                        </li>
                                        
                                        <li v-if="currentPage < totalPages" class="page-item">
                                            <a class="page-link rounded-2 border-0 text-secondary px-2" href="#" @click.prevent="changePage(currentPage + 1)">
                                                {{ currentPage + 1 }}
                                            </a>
                                        </li>
                                        
                                        <li v-if="currentPage < totalPages - 2" class="page-item disabled">
                                            <span class="page-link border-0 bg-transparent text-secondary px-2">...</span>
                                        </li>
                                        
                                        <li v-if="currentPage < totalPages - 1" class="page-item">
                                            <a class="page-link rounded-2 border-0 text-secondary px-2" href="#" @click.prevent="changePage(totalPages)">
                                                {{ totalPages }}
                                            </a>
                                        </li>
                                        
                                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                            <a class="page-link rounded-2 border-0 text-secondary px-2" href="#" @click.prevent="changePage(currentPage + 1)">
                                                <i class="fa-solid fa-chevron-right fa-xs"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer gap-2">
                    <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <button type="button" class="btn btn-light btn-block" data-bs-dismiss="modal">{{ $t('accountPages.address.list.addressElements.closeBtn') }}</button>
                    </div>
                    <div class="col-lg-4 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <div class="d-flex align-items-center gap-2">
                            <div class="btn-group btn-group-md" role="group">
                                <button type="button" 
                                        class="btn btn-outline-success d-flex align-items-center gap-1" 
                                        @click="exportToExcel" 
                                        :disabled="excelExportLoading">
                                    <span v-if="excelExportLoading" class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">{{ $t('accountPages.zerduzSettings.announcements.modal.results.export.loading') }}</span>
                                    </span>
                                    <i v-else class="fa-solid fa-file-excel"></i>
                                    <small>{{ $t('accountPages.zerduzSettings.announcements.modal.results.export.buttons.excel') }}</small>
                                </button>
                                <button type="button" 
                                        class="btn btn-outline-danger d-flex align-items-center gap-1" 
                                        @click="exportToPDF" 
                                        :disabled="pdfExportLoading">
                                    <span v-if="pdfExportLoading" class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">{{ $t('accountPages.zerduzSettings.announcements.modal.results.export.loading') }}</span>
                                    </span>
                                    <i v-else class="fa-solid fa-file-pdf"></i>
                                    <small>{{ $t('accountPages.zerduzSettings.announcements.modal.results.export.buttons.pdf') }}</small>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="repeatModal" role="dialog" tabindex="-1" aria-labelledby="repeatModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5 d-flex align-items-center gap-2" id="repeatModalLabel">Duyuru Tekrarla <small class="text-muted" style="font-size: 14px;" v-if="repeatAnnouncementData.announcement?.title">({{ repeatAnnouncementData.announcement?.title }})</small></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <div class="mb-3">
                            <label for="repeatAnnouncementUsers" class="form-label">Kullanıcılar</label>
                            <Multiselect 
                                v-model="repeatAnnouncementData.users" 
                                id="repeatAnnouncementUsers" 
                                :options="userOptions" 
                                @clear="userOptionsCleared('repeat')" 
                                @select="userOptionsSelectedRepeat" 
                                @deselect="userOptionsDeselected('repeat')" 
                                track-by="label" 
                                label="label" 
                                :multiple="true" 
                                :searchable="true" 
                                :close-on-select="false" 
                                :hide-selected="true" 
                                mode="tags">
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag is-country text-wrap" :class="{ 'is-disabled': disabled }">
                                        {{ option.label }}
                                        <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                    </div>
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div id="repeat-modal-alertbox"></div>
                </div>
                <div class="modal-footer gap-2">
                    <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <button type="button" class="btn btn-light btn-block" data-bs-dismiss="modal" id="closeRepeatModal">Kapat</button>
                    </div>
                    <div class="col-lg-5 mx-auto col-sm-4 col-4 col-md-4 d-grid">
                        <button type="button" class="btn btn-primary btn-block" @click="repeatAnnouncement" :disabled="repeatStatus === 'loading'">
                            <span v-if="repeatStatus === 'loading'" class="spinner-border spinner-border-sm me-2" role="status">
                                <span class="visually-hidden">Yükleniyor...</span>
                            </span>
                            Tekrarla
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import {
    getZerduzAnnouncements,
    getZerduzUsers,
    makePoll,
    repeatPoll,
    deletePoll,
    getRate
} from "@/helpers/api";
import { Modal } from 'bootstrap';
import '@vueform/multiselect/themes/default.css';
import {
    alert,
    destroyAlerts
} from "@/helpers/funcs";
import accountPageSidemenu from '@/components/accountPageSidemenu';

import ExcelJS from 'exceljs';
import html2pdf from 'html2pdf.js';

export default {
    name: "AccountZerduzSettingsAnnouncements",
    components: {
        accountPageSidemenu,
        Multiselect
    },
    data() {
        return {
            token: window.localStorage.getItem('token'),
            userId: window.localStorage.getItem('userId'),
            zid: this.$store.state.zid,
            imageUrl: 'https://api.zerduz.com/poll/',
            announcements: [],
            announcementsLoading: true,
            addAnnouncementData: {
                title: '',
                description: '',
                image: '',
                selectedImage: '',
                users: ["all"],
                hasOptions: false,
                optionImages: [],
                selectedOptionImages: [],
                optionDescriptions: ['', '', '', '']
            },
            users: [],
            addStatus: '',
            addStatusMessage: '',
            deleteAnnouncementModal: true,
            deleteAnnouncementData: {
                image: '',
                title: ''
            },
            resultsModal: false,
            resultsModalDataLoading: false,
            resultsModalData: {
                data: [],
                results: []
            },
            resultOptions: [
                {
                    value: 0,
                    option: this.$t('accountPages.zerduzSettings.announcements.options.dislike')
                },
                {
                    value: 1,
                    option: this.$t('accountPages.zerduzSettings.announcements.options.like')
                }, 
                {
                    value: 2,
                    option: this.$t('accountPages.zerduzSettings.announcements.options.ok')
                }
            ],
            excelExportLoading: false,
            pdfExportLoading: false,
            currentPage: 1,
            itemsPerPage: 5,
            currentAnnouncementPage: 1,
            announcementsPerPage: 5,
            repeatAnnouncementData: {
                announcement: null,
                users: ["all"]
            },
            repeatStatus: 'idle'
        };
    },
    mounted() {
        this.getUsers();
        this.getAnnouncements();
    },
    computed: {
        userOptions() {
            return [{ value: "all", label: this.$t('accountPages.zerduzSettings.announcements.modal.add.form.all') }, ...this.users.map(user => ({
                value: user.id,
                label: (user.user_name.replace('. ', '')) + (user.email ? ' (' + user.email + ')' : ''),
                email: user.email
            })).filter(user => user.email)];
        },
        paginatedResults() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.resultsModalData.results.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.resultsModalData.results.length / this.itemsPerPage);
        },
        paginatedAnnouncements() {
            const start = (this.currentAnnouncementPage - 1) * this.announcementsPerPage;
            const end = start + this.announcementsPerPage;

            var announcements = this.announcements;
            var sortedAnnouncements = announcements.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            return sortedAnnouncements.slice(start, end);
        },
        totalAnnouncementPages() {
            return Math.ceil(this.announcements.length / this.announcementsPerPage);
        }
    },
    methods: {
        async exportToExcel() {
            this.excelExportLoading = true;
            try {
                const workbook = new ExcelJS.Workbook();
                workbook.creator = 'Zerduz';
                workbook.created = new Date();

                const summarySheet = workbook.addWorksheet(this.$t('accountPages.zerduzSettings.announcements.modal.results.export.excel.sheets.summary'));
                const detailSheet = workbook.addWorksheet(this.$t('accountPages.zerduzSettings.announcements.modal.results.export.excel.sheets.details'));

                const titleStyle = {
                    font: { size: 16, bold: true, color: { argb: '2B5BA1' } },
                    alignment: { horizontal: 'center', vertical: 'middle' }
                };

                const subtitleStyle = {
                    font: { size: 12, bold: true, color: { argb: '404040' } },
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5' } },
                    alignment: { horizontal: 'left', vertical: 'middle' }
                };

                const normalStyle = {
                    font: { size: 11, color: { argb: '666666' } },
                    alignment: { vertical: 'middle', wrapText: true }
                };

                const statsStyle = {
                    font: { size: 11, bold: true },
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E8F0FE' } },
                    alignment: { horizontal: 'center', vertical: 'middle' }
                };

                summarySheet.mergeCells('A1:C1');
                const titleRow = summarySheet.getRow(1);
                titleRow.height = 40;
                titleRow.getCell(1).value = this.$t('accountPages.zerduzSettings.announcements.modal.results.report.title');
                titleRow.getCell(1).style = titleStyle;

                summarySheet.addRow([]);

                const detailRows = [
                    [this.$t('accountPages.zerduzSettings.announcements.modal.results.report.fields.title'), this.resultsModalData.data.title],
                    [this.$t('accountPages.zerduzSettings.announcements.modal.results.report.fields.description'), this.resultsModalData.data.description],
                    [this.$t('accountPages.zerduzSettings.announcements.modal.results.report.fields.createdAt'), this.formatDate(this.resultsModalData.data.created_at)]
                ];

                detailRows.forEach((row) => {
                    const excelRow = summarySheet.addRow(row);
                    excelRow.getCell(1).style = subtitleStyle;
                    excelRow.getCell(2).style = normalStyle;
                    excelRow.height = 25;
                });

                summarySheet.addRow([]);
                const statsHeaderRow = summarySheet.addRow([this.$t('accountPages.zerduzSettings.announcements.modal.results.report.statistics.title')]);
                statsHeaderRow.getCell(1).style = titleStyle;
                summarySheet.mergeCells(`A${statsHeaderRow.number}:C${statsHeaderRow.number}`);

                const totalRow = summarySheet.addRow([
                    this.$t('accountPages.zerduzSettings.announcements.modal.results.report.statistics.totalResponses'), 
                    this.resultsModalData.results.length
                ]);
                totalRow.getCell(1).style = subtitleStyle;
                totalRow.getCell(2).style = statsStyle;

                const optionsHeaderRow = summarySheet.addRow([this.$t('accountPages.zerduzSettings.announcements.modal.results.report.statistics.optionDistribution')]);
                optionsHeaderRow.getCell(1).style = subtitleStyle;

                if (this.resultsModalData.data.images_count > 1) {
                    for (let i = 2; i <= 5; i++) {
                        const count = this.resultsModalData.results.filter(item => item.child_image == i).length;
                        const description = this.resultsModalData.data.parsedDescriptions?.[['first', 'second', 'third', 'fourth'][i-2]] || '';
                        
                        const optionRow = summarySheet.addRow([
                            this.$t('accountPages.zerduzSettings.announcements.modal.results.report.options.option', { number: i - 1 }),
                            count,
                            description
                        ]);
                        
                        optionRow.getCell(1).style = normalStyle;
                        optionRow.getCell(2).style = statsStyle;
                        optionRow.getCell(3).style = {
                            ...normalStyle,
                            alignment: { vertical: 'middle', wrapText: true }
                        };
                    }
                } else {
                    this.resultOptions.forEach(option => {
                        const count = this.resultsModalData.results.filter(item => item.option == option.value).length;
                        const optionRow = summarySheet.addRow([option.option, count]);
                        optionRow.getCell(1).style = normalStyle;
                        optionRow.getCell(2).style = statsStyle;
                    });
                }

                detailSheet.columns = [
                    { header: this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.user'), key: 'user', width: 30 },
                    { header: this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.email'), key: 'email', width: 35 },
                    { header: this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.zerduzId'), key: 'zid', width: 15 },
                    { header: this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.option'), key: 'option', width: 20 },
                    { header: this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.date'), key: 'date', width: 25 }
                ];

                const headerRow = detailSheet.getRow(1);
                headerRow.font = { bold: true, size: 12, color: { argb: 'FFFFFF' } };
                headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '2B5BA1' } };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle' };
                headerRow.height = 30;

                this.resultsModalData.results.forEach(result => {
                    const row = detailSheet.addRow({
                        user: result.user_name.replace('. ', ' '),
                        email: result.email,
                        zid: result.zid,
                        option: result.child_image 
                            ? this.$t('accountPages.zerduzSettings.announcements.modal.results.report.options.option', { number: result.child_image - 1 })
                            : this.resultOptions.find(item => item.value == result.option)?.option 
                              ? this.$t('accountPages.zerduzSettings.announcements.modal.results.report.options.' + this.resultOptions.find(item => item.value == result.option).option.toLowerCase())
                              : result.option,
                        date: this.formatDate(result.created_at)
                    });
                    row.height = 25;
                    row.alignment = { vertical: 'middle' };
                });

                summarySheet.getColumn(1).width = 20;
                summarySheet.getColumn(2).width = 15;
                summarySheet.getColumn(3).width = 50;

                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${this.resultsModalData.data.title}_${this.$t('accountPages.zerduzSettings.announcements.modal.results.export.excel.filename')}.xlsx`;
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.exportError.excel'), 5000);
            } finally {
                this.excelExportLoading = false;
            }
        },
        async exportToPDF() {
            this.pdfExportLoading = true;
            try {
                const mainImageUrl = this.imageUrl + this.resultsModalData.data.image + '-1';
                const mainImageBase64 = await this.getImageBase64(mainImageUrl);

                let optionsHtml = '';
                if (this.resultsModalData.data.images_count > 1) {
                    const optionImages = [];
                    for (let i = 0; i < 4; i++) {
                        const optionImageUrl = this.imageUrl + this.resultsModalData.data.image + '-' + (i + 2);
                        const optionImageBase64 = await this.getImageBase64(optionImageUrl);
                        const count = this.resultsModalData.results.filter(item => item.child_image == (i + 2)).length;
                        const description = this.resultsModalData.data.parsedDescriptions?.[['first', 'second', 'third', 'fourth'][i]] || '';
                        
                        optionImages.push(`
                            <div style="border: 1px solid #eee; padding: 15px; border-radius: 8px;">
                                <h4 style="margin: 0 0 10px 0; color: #404040;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.options.option', { number: i + 1 })}</h4>
                                <img src="${optionImageBase64}" style="width: 100%; height: auto; border-radius: 4px;" />
                                ${description ? `<p style="margin: 10px 0; color: #666; font-size: 14px;">${description}</p>` : ''}
                                <p style="margin: 10px 0 0 0; text-align: center; color: #666;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.options.count', { count })}</p>
                            </div>
                        `);
                    }
                    optionsHtml = `
                        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px; margin-bottom: 20px;">
                            ${optionImages.join('')}
                        </div>
                    `;
                } else {
                    optionsHtml = `
                        <div style="display: flex; justify-content: space-around; margin-bottom: 20px;">
                            ${this.resultOptions.map(option => {
                                const count = this.resultsModalData.results.filter(item => item.option == option.value).length;
                                return `
                                    <div style="text-align: center;">
                                        <h4 style="margin: 0 0 10px 0; color: #404040;">${option.option}</h4>
                                        <p style="margin: 0; color: #666;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.options.count', { count })}</p>
                                    </div>
                                `;
                            }).join('')}
                        </div>
                    `;
                }

                const content = `
                    <div style="padding: 20px;">
                        <div style="page-break-after: always;">
                            <h2 style="color: #2B5BA1; text-align: center; margin-bottom: 30px;">${String(this.$t('accountPages.zerduzSettings.announcements.modal.results.report.title')).replace(/ /g, '&emsp;')}</h2>
                            
                            <div style="margin-bottom: 30px;">
                                <img src="${mainImageBase64}" style="max-width: 100%; height: auto; margin-bottom: 20px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);" />
                                
                                <h3 style="color: #404040; font-size: 16px;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.details')}</h3>
                                <table style="width: 100%; margin-bottom: 20px;">
                                    <tr>
                                        <td style="padding: 8px; background: #F5F5F5; font-weight: bold;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.fields.title')}</td>
                                        <td style="padding: 8px;">${this.resultsModalData.data.title}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 8px; background: #F5F5F5; font-weight: bold;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.fields.description')}</td>
                                        <td style="padding: 8px;">${this.resultsModalData.data.description}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 8px; background: #F5F5F5; font-weight: bold;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.fields.createdAt')}</td>
                                        <td style="padding: 8px;">${this.formatDate(this.resultsModalData.data.created_at)}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div style="margin-bottom: ${this.resultsModalData.data.images_count == 1 ? '60px' : '30px'};">
                            <h3 style="color: #404040; font-size: 16px;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.options')}</h3>
                            ${optionsHtml}
                        </div>

                        <div style="page-break-inside: avoid;">
                            <h3 style="color: #404040; font-size: 16px;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.detailedResults')}</h3>
                            <table style="width: 100%; border-collapse: collapse;">
                                <thead>
                                    <tr style="page-break-inside: avoid; background: #2B5BA1; color: white;">
                                        <th style="padding: 12px; text-align: left;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.user')}</th>
                                        <th style="padding: 12px; text-align: left;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.email')}</th>
                                        <th style="padding: 12px; text-align: left;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.zerduzId')}</th>
                                        <th style="padding: 12px; text-align: left;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.table.option')}</th>
                                        <th style="padding: 12px; text-align: left;">${this.$t('accountPages.zerduzSettings.announcements.modal.results.table.date')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${this.resultsModalData.results.map(result => `
                                        <tr style="page-break-inside: avoid; border-bottom: 1px solid #eee;">
                                            <td style="padding: 8px;">${result.user_name.replace('. ', ' ')}</td>
                                            <td style="padding: 8px;">${result.email}</td>
                                            <td style="padding: 8px;">${result.zid}</td>
                                            <td style="padding: 8px;">${result.child_image 
                                                ? `${this.$t('accountPages.zerduzSettings.announcements.modal.results.report.options.option', { number: (result.child_image - 1) })}` 
                                                : this.resultOptions.find(item => item.value == result.option)?.option || result.option}</td>
                                            <td style="padding: 8px;">${this.formatDate(result.created_at)}</td>
                                        </tr>
                                    `).join('')}
                                </tbody>
                            </table>
                        </div>
                    </div>
                `;

                const opt = {
                    margin: 1,
                    filename: `${this.resultsModalData.data.title}_${this.$t('accountPages.zerduzSettings.announcements.modal.results.export.excel.filename')}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
                };

                const element = document.createElement('div');
                element.innerHTML = content;
                await html2pdf().set(opt).from(element).save();
            } catch (error) {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.exportError.pdf'), 5000);
            } finally {
                this.pdfExportLoading = false;
            }
        },
        async getImageBase64(url) {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                return '';
            }
        },
        userOptionsCleared(type) {
            if (type == 'repeat') {
                this.repeatAnnouncementData.users = ["all"];
            } else {
                this.addAnnouncementData.users = ["all"];
            }
        },
        userOptionsSelected(selectedOption) {
            if (selectedOption === "all") {
                this.addAnnouncementData.users = ["all"];
            } else {
                if (this.addAnnouncementData.users.some(user => user === "all")) {
                    this.addAnnouncementData.users = [selectedOption];
                }
            }
        },
        userOptionsSelectedRepeat(selectedOption) {
            if (selectedOption === "all") {
                this.repeatAnnouncementData.users = ["all"];
            } else {
                if (this.repeatAnnouncementData.users.some(user => user === "all")) {
                    this.repeatAnnouncementData.users = [selectedOption];
                }
                }
        },
        userOptionsDeselected(type) {
            if (type == 'repeat') {
                if (this.repeatAnnouncementData.users.length == 0) {
                    this.repeatAnnouncementData.users = ["all"];
                }
            } else {
                if (this.addAnnouncementData.users.length == 0) {
                    this.addAnnouncementData.users = ["all"];
                }
            }
        },
        showAddAnnouncementModal() {
            this.addAnnouncementData = {
                title: '',
                description: '',
                image: '',
                selectedImage: '',
                users: ["all"],
                hasOptions: false,
                optionImages: [],
                selectedOptionImages: [],
                optionDescriptions: ['', '', '', '']
            };

            const fileInput = document.getElementById('formFile');
            if (fileInput) {
                fileInput.value = '';
            }

            const modalElement = document.getElementById('addAnnouncementModal');
            const modal = new Modal(modalElement);
            modal.show();
        },
        async showResultsModal(announcement) {
            try {
                this.currentPage = 1;
                this.resultsModalDataLoading = true;
                const { response, success } = await getRate(this.token, announcement.image);

                if (success) {
                    if (announcement.images_description) {
                        try {
                            announcement.parsedDescriptions = JSON.parse(announcement.images_description);
                        } catch (e) {
                            announcement.parsedDescriptions = {};
                        }
                    }
                    
                    this.resultsModalData.data = announcement;
                    if (response.data && response.data.length > 0) {
                        this.resultsModalData.results = response.data;
                    } else {
                        this.resultsModalData.results = [];
                    }

                    const modalElement = document.getElementById('resultsModal');
                    const modal = new Modal(modalElement);
                    modal.show();
                } else {
                    this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.viewResultsError'), 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.viewResultsError'), 5000);
            } finally {
                this.resultsModalDataLoading = false;
            }
        },
        async tryToDeleteAnnouncement() {
            const {
                success
            } = await deletePoll(this.token, this.deleteAnnouncementData.image);
            if (success) {
                this.deleteAnnouncementData.image = '';
                this.deleteAnnouncementData.title = '';
                
                this.getAnnouncements();

                document.getElementById('closeDeleteModal').click();
            } else {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.deleteError'), 5000);
            }
        },
        deleteAnnouncement(image, title) {
            this.deleteAnnouncementData.image = image;
            this.deleteAnnouncementData.title = title;

            const modalElement = document.getElementById('deleteModal');
            const modal = new Modal(modalElement);
            modal.show();
        },
        async addAnnouncement() {
            this.addStatus = 'loading';
            try {
                if (this.addAnnouncementData) {
                    if (this.addAnnouncementData.title && this.addAnnouncementData.title.length > 0) {
                        if (this.addAnnouncementData.description && this.addAnnouncementData.description.length > 0) {
                            if (this.addAnnouncementData.image && this.addAnnouncementData.image.length > 0) {
                                if (this.addAnnouncementData.users && this.addAnnouncementData.users.length > 0) {
                                    try {
                                        const calculateTotalSize = (files) => {
                                            return files.reduce((total, file) => total + (file instanceof File ? file.size : 0), 0);
                                        };

                                        const bytesToMB = (bytes) => {
                                            return bytes / (1024 * 1024);
                                        };

                                        var images = [];

                                        images.push(this.addAnnouncementData.selectedImage);

                                        if (this.addAnnouncementData.hasOptions && this.addAnnouncementData.optionImages && this.addAnnouncementData.optionImages.length == 4) {
                                            for (let i = 0; i < this.addAnnouncementData.selectedOptionImages.length; i++) {
                                                if (this.addAnnouncementData.selectedOptionImages[i]) {
                                                    images.push(this.addAnnouncementData.selectedOptionImages[i]);
                                                }
                                            }
                                        }

                                        const totalSize = calculateTotalSize(images);
                                        const totalSizeMB = bytesToMB(totalSize);
                                        
                                        if (totalSizeMB > 60) {
                                            alert("#modal-alertbox", this.$t('accountPages.zerduzSettings.announcements.errors.fileSizeLimit', { size: totalSizeMB.toFixed(2) }), "danger", "mt-2");
                                        } else {
                                            if (this.addAnnouncementData.hasOptions) {
                                                if (this.addAnnouncementData.optionImages && this.addAnnouncementData.optionImages.length == 4 && this.addAnnouncementData.optionImages.every(img => img)) {
                                                    destroyAlerts();
                                                    await this.tryToAddAnnouncementData();
                                                } else {
                                                    alert("#modal-alertbox", this.$t('accountPages.zerduzSettings.announcements.errors.selectAllImages'), "danger", "mt-2");
                                                }
                                            } else {
                                                destroyAlerts();
                                                await this.tryToAddAnnouncementData();
                                            }
                                        }
                                    } catch (error) {
                                        if (this.addAnnouncementData.hasOptions) {
                                            if (this.addAnnouncementData.optionImages && this.addAnnouncementData.optionImages.length == 4 && this.addAnnouncementData.optionImages.every(img => img)) {
                                                destroyAlerts();
                                                await this.tryToAddAnnouncementData();
                                            } else {
                                                alert("#modal-alertbox", this.$t('accountPages.zerduzSettings.announcements.errors.selectAllImages'), "danger", "mt-2");
                                            }
                                        } else {
                                            destroyAlerts();
                                            await this.tryToAddAnnouncementData();
                                        }
                                    }
                                } else {
                                    alert("#modal-alertbox", this.$t('accountPages.zerduzSettings.announcements.errors.selectUser'), "danger", "mt-2");
                                }
                            } else {
                                alert("#modal-alertbox", this.$t('accountPages.zerduzSettings.announcements.errors.selectImage'), "danger", "mt-2");
                            }
                        } else {
                            alert("#modal-alertbox", this.$t('accountPages.zerduzSettings.announcements.errors.enterDescription'), "danger", "mt-2");
                        }
                    } else {
                        alert("#modal-alertbox", this.$t('accountPages.zerduzSettings.announcements.errors.enterTitle'), "danger", "mt-2");
                    }
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.errors.addFailed'), 5000);
            } finally {
                this.addStatus = 'idle';
            }
        },
        async tryToAddAnnouncementData() {
            try {
                const form = new FormData();

                form.append('title', String(this.addAnnouncementData.title));
                form.append('description', String(this.addAnnouncementData.description));
                form.append('file1', this.addAnnouncementData.selectedImage);
                
                var users = [];

                if (this.addAnnouncementData.users.includes("all")) {
                    users = this.users.filter(user => user.email).map(user => user.email);
                } else {
                    users = this.addAnnouncementData.users.map(user => this.users.find(u => u.id == user).email);
                }

                for (var i = 0; i < users.length; i++) {
                    form.append('receivers', users[i]);
                }

                if (this.addAnnouncementData.hasOptions) {
                    const descriptionsObj = {};
                    
                    for (let i = 0; i < this.addAnnouncementData.selectedOptionImages.length; i++) {
                        if (this.addAnnouncementData.selectedOptionImages[i]) {
                            form.append('file' + (i + 2), this.addAnnouncementData.selectedOptionImages[i]);
                            
                            const keys = ['first', 'second', 'third', 'fourth'];
                            if (this.addAnnouncementData.optionDescriptions[i]) {
                                descriptionsObj[keys[i]] = this.addAnnouncementData.optionDescriptions[i];
                            }
                        }
                    }
                    
                    if (Object.keys(descriptionsObj).length > 0) {
                        form.append('descriptions', JSON.stringify(descriptionsObj));
                    }
                }

                const { success, error } = await makePoll(this.token, form);

                if (success) {
                    document.getElementById('closeAddAnnouncementModal').click();
                    this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.addSuccess'), 5000);
                    this.getAnnouncements();
                } else {
                    if (error.response.data == "Sender Address No valid") {
                        this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.addError.noEmail'), 5000);
                    } else {
                        this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.addError.general'), 5000);
                    }
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.errors.addFailed'), 5000);
            }
        },
        handleAddImage(event) {
            if (event && event.target && event.target.files && event.target.files.length > 0) {
                this.addAnnouncementData.selectedImage = event.target.files[0];

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.addAnnouncementData.image = e.target.result;
                };

                reader.readAsDataURL(this.addAnnouncementData.selectedImage);
            }
        },
        handleOptionImage(event, index) {
            if (event && event.target && event.target.files && event.target.files.length > 0) {
                const file = event.target.files[0];
                this.addAnnouncementData.selectedOptionImages[index] = file;

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.addAnnouncementData.optionImages[index] = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        async getUsers() {
            try {
                const { response, success } = await getZerduzUsers(this.token);

                if (success) {
                    this.users = response.data;
                } else {
                    this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.loadUsersError'), 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.loadUsersError'), 5000);
            }
        },
        async getAnnouncements() {
            try {
                const { response, success } = await getZerduzAnnouncements(this.token);

                if (success) {
                    this.announcementsLoading = false;
                    this.announcements = response.data;
                } else {
                    this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.loadAnnouncementsError'), 5000);
                }
            } catch (error) {
                this.$showToast("Zerduz", this.$t('accountPages.zerduzSettings.announcements.toasts.loadAnnouncementsError'), 5000);
            }
        },
        formatDate(sourceDate) {
            const date = new Date(sourceDate);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                timeZone: 'Europe/Istanbul'
            };

            return date.toLocaleString(this.$i18n.locale, options);
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        changeAnnouncementPage(page) {
            if (page >= 1 && page <= this.totalAnnouncementPages) {
                this.currentAnnouncementPage = page;
            }
        },
        showRepeatModal(announcement) {
            this.repeatAnnouncementData.announcement = announcement;
            this.repeatAnnouncementData.users = ["all"];
            
            const modalElement = document.getElementById('repeatModal');
            const modal = new Modal(modalElement);
            modal.show();
        },
        async repeatAnnouncement() {
            this.repeatStatus = 'loading';
            try {
                if (this.repeatAnnouncementData.users && this.repeatAnnouncementData.users.length > 0) {
                    var receivers = [];
                    var poll_id = this.repeatAnnouncementData.announcement.id;
                   
                    if (this.repeatAnnouncementData.users.includes("all")) {
                        receivers = this.users.filter(user => user.email).map(user => user.email);
                    } else {
                        receivers = this.repeatAnnouncementData.users.map(user => this.users.find(u => u.id == user).email);
                    }

                    const { success, error } = await repeatPoll(this.token, receivers, poll_id);
                    
                    if (success) {
                        document.getElementById('closeRepeatModal').click();
                        this.$showToast("Zerduz", "Duyuru başarıyla tekrarlandı", 5000);
                        await this.getAnnouncements();
                    } else {
                        if (error.response?.data === "Sender Address No valid") {
                            alert("#repeat-modal-alertbox", "Gönderici adresi geçerli değil", "danger", "mt-2");
                        } else {
                            alert("#repeat-modal-alertbox", "Duyuru tekrarlanırken bir hata oluştu", "danger", "mt-2");
                        }
                    }
                } else {
                    alert("#repeat-modal-alertbox", "Lütfen en az bir kullanıcı seçiniz", "danger", "mt-2");
                }
            } catch (error) {
                this.$showToast("Zerduz", "Duyuru tekrarlanırken bir hata oluştu", 5000);
            } finally {
                this.repeatStatus = 'idle';
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        const zid = window.localStorage.getItem('zid');
       
        if (!zid ) {
            next('/Account/Orders');
            this.$showToast("Zerduz", "Duyuru yönetimi için ZID gereklidir", 5000);
        } else {
            next();
        }
    }
};
</script>

<style scoped src="../assets/css/account.css"/>
<style scoped>
.multiselect-tag.content-country {
    padding: 2px 8px !important;
    font-size: 12px !important;
}

.multiselect-tag.ad-position {
    padding: 2px 8px !important;
    font-size: 16px !important;
}

.multiselect-tag.is-country {
    padding: 5px 8px;
    border-radius: 22px;
    background: #ea73fb;
    margin: 3px 3px 8px;
    border: 0px;
    font-size: 16px;
}

.multiselect-tag.is-country img {
    width: 28px;
    border-radius: 50%;
    height: 24px;
    margin-right: 8px;
    border: 2px solid #ffffffbf;
}

.multiselect-tag.is-country i:before {
    color: #ffffff;
    border-radius: 50%;
    ;
}

.multiselect-dropdown {
    border-radius: 0 0 4px 4px !important;
}

.pagination .page-link {
    color: #666;
    background-color: transparent;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    margin: 0 2px;
    transition: all 0.2s ease;
}

.pagination .page-item.active .page-link {
    background-color: var(--bs-primary);
    color: white;
}

.pagination .page-link:hover:not(.active):not(.disabled) {
    background-color: #f0f0f0;
    color: var(--bs-primary);
}

.pagination .page-item.disabled .page-link {
    background-color: transparent;
    opacity: 0.5;
    cursor: not-allowed;
}
</style>