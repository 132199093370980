import $ from 'jquery';
import store from '../store';
import router from '../router';
import { uuid } from "vue-uuid";
import { i18n } from '@/main';
import { getPrices } from '@/helpers/api';

var lang = (data) => { 
  return i18n.global.t(data);
};

let toasts = [];

export const alert = async (element, message, type, additionalClass, id = 1) => {
  var alertElement = $(element);
  if ($('[id^="alert-"]').length === 0) {
    const wrapper = $(
      `<div class="alert alert-${type} alert-dismissible fade show ${additionalClass}" role="alert" id="alert-${id}">
            <div>${message}</div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>`
    );

    alertElement.append(wrapper);
    $(`#alert-${id}`).hide().slideDown(500);
  } else {
    $('[id^="alert-"]').each(function () {
      $(this).slideUp(500, function () {
        $(this).slideUp(500);
        $(this).remove();
        const wrapper = $(
          `<div class="alert alert-${type} alert-dismissible fade show ${additionalClass}" role="alert" id="alert-${id}">
                <div>${message}</div>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>`
        );

        alertElement.append(wrapper);
        $(`#alert-${id}`).hide().slideDown(500);
      });
    });
  }
};

export const destroyAlerts = async () => {
  $('[id^="alert-"]').each(function () {
    $(this).slideUp(500, function () {
      $(this).slideUp(500);
      $(this).remove();
    });
  });
};

export const incrementValue = async (e) => {
  e.preventDefault();
  var fieldName = $(e.target).data('field');
  var parent = $(e.target).closest('div');
  var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);
  
  if (!isNaN(currentVal)) {
    parent.find('input[name=' + fieldName + ']').val(currentVal + 1);
  } else {
    parent.find('input[name=' + fieldName + ']').val(0);
  }
};

export const decrementValue = async (e) => {
  e.preventDefault();
  var fieldName = $(e.target).data('field');
  var parent = $(e.target).closest('div');
  var currentVal = parseInt(parent.find('input[name=' + fieldName + ']').val(), 10);

  if (!isNaN(currentVal) && currentVal > 0) {
    parent.find('input[name=' + fieldName + ']').val(currentVal - 1);
  } else {
    parent.find('input[name=' + fieldName + ']').val(0);
  }
};

export const authTimedOut = async (redirect=true, redirectUrl=null) => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('email');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('cart');
  localStorage.removeItem('zid');
  store.dispatch('changeCartItem', { cart: undefined });
  store.dispatch('logout');
  if ((router.currentRoute._value.path != "/Login" && router.currentRoute._value.path != "/Register") && redirect == true) {
    if (redirectUrl == null && router.currentRoute._value.meta.requiresLogin) {
      router.push('/Login');
    } else if (redirectUrl == null) {
      router.go();
    } else if (redirectUrl != null) {
      router.push(redirectUrl);
    } else {
      router.push('/');
    }
  }
};

export const formatPriceFunc = async (price) => {
  const cleanedPrice = price.replace(/[^\d,.-]/g, '');
  const floatValue = parseFloat(cleanedPrice.replace(',', '.'));

  var decimalPart = (floatValue % 1 === 0) ? '00' : (floatValue % 1).toFixed(2).split('.')[1];

  const integerPart = Math.floor(floatValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (decimalPart == '00') {
      decimalPart = null;
  }
  const result = `${integerPart}${decimalPart ? ',' + decimalPart : ''}`;

  return result;
};

export const getCurrency = (data) => {
  // var country = "en";
  // if (localStorage.getItem('country') && localStorage.getItem('country') != 'select') {
  //   country = String(localStorage.getItem('country'));
  // } else {
  //   country = "en";
  // }
  try {
    const formatter = new Intl.NumberFormat("en", {
      style: 'currency',
      currency: data,
    });

    var currency = formatter.formatToParts(0);
    currency = currency.find(part => part.type === 'currency').value;
    
    return currency;
    // return formatter.format(price);
  } catch {
    return 0;
  }
};

export const getPrice = async (tax=true, string=false) => {
  const { success, data } = await getPrices(1, localStorage.getItem('country'));
  if (success && data && data.length > 0) {
    var basePrice = parseFloat(data[0].price);
    var price = 0;
    if (tax == true) {
      price = (basePrice + (basePrice * 0.20));
    } else {
      price = basePrice;
    }
  
    return (string == true ? String(price) : parseFloat(price));
  } else {
    return (string == true ? "0" : 0);
  }
};

export const getAllPrices = async () => {
  const { success, data } = await getPrices(1, localStorage.getItem('country'));
  if (success && data && data.length > 0) {
    var currency = data[0].currency;

    var basePrice = parseFloat(data[0].price);
    var price = 0;
    var priceWithTax = 0;
    
    priceWithTax = (basePrice + (basePrice * 0.20));
    price = basePrice;
    
    return { price: { withoutTax: parseFloat(price), tax: parseFloat(priceWithTax) }, string: { withoutTax: String(price), tax: String(priceWithTax) }, currency: currency };
  } else {
    return { price: { withoutTax: 0, tax: 0 }, string: { withoutTax: "0", tax: "0" }, currency: "$" };
  }
};

export const validateTcNo = (tcno) => {
  const digitCount = 10;
  if (tcno.length !== (digitCount + 1)) {
    return { validate: false, text: lang("errors.validation.tcnoShouldBeElevenCharacter") };
  }
  
  let total = 0;
  for (let i = 0; i < digitCount; i++) {
    total += Number(tcno.charAt(i));
  }

  const calculatedOnesDigit = String(total).slice(-1);
  const providedOnesDigit = tcno.charAt(digitCount);

  if (calculatedOnesDigit === providedOnesDigit) {
    return { validate: true };
  } else {
    return { validate: false, text: lang("errors.validation.tcnoValidText") };
  }
};

export const showToast = (title, description, autoHide = false, showLogo = true, dismissable = true, show = true) => {
  var toastID = uuid.v1();
  toasts.push({ id: toastID, show, title, showLogo, dismissable, description, autoHide });
  if (autoHide != null && autoHide != false) {
    setTimeout(() => {
      closeToast(toastID);
    }, autoHide);
  }
  return toastID;
};

export const hideToast = (id) => {
  $('#' + id).fadeOut(() => {
    toasts.find(item => item.id == id).show = false;
  });
};

export const closeToast = (id) => {
  var index = toasts.indexOf(toasts.find(item => item.id == id));
  if (index > -1) {
    $('#' + toasts[index].id).fadeOut(() => {
      toasts.splice(index, 1);
    });
  }
};

function showToasts() {
  return toasts;
}
export { showToasts, toasts };