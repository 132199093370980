<template>
<div>
    <main class="form-signin w-100 m-auto">
        <div>
            <section class="success-section">
                <i class="fa-solid fa-circle-check fs-1 d-flex justify-content-center mb-4 icon"></i>
                <h1 class="h3 mb-3 text-center success-header">{{ $t('verifyEmailPage.success') }}</h1>

                <small v-if="$t('verifyEmailPage.successDescription.first') == 'text'" class="fs-5 d-flex justify-content-center text-center success-text">{{ $t('verifyEmailPage.successDescription.text') }}&nbsp;<router-link to="/login" class="d-inline-flex fst-italic text-decoration-none">{{ $t('verifyEmailPage.successDescription.link') }}</router-link></small>
                <small v-if="$t('verifyEmailPage.successDescription.first') == 'link'" class="fs-5 d-flex justify-content-center text-center success-text"><router-link to="/login" class="d-inline-flex fst-italic text-decoration-none">{{ $t('verifyEmailPage.successDescription.link') }}</router-link>&nbsp;{{ $t('verifyEmailPage.successDescription.text') }}</small>
            </section>
            <section class="error-section">
                <i class="fa-solid fa-circle-exclamation fs-1 d-flex justify-content-center mb-4 icon error-icon"></i>
                <h1 class="h3 mb-3 text-center">{{ $t('verifyEmailPage.error.title') }}</h1>

                <small v-if="$t('verifyEmailPage.error.description.first') == 'text'" class="fs-5 d-block justify-content-center text-center error-text">{{ $t('verifyEmailPage.error.description.text') }}&nbsp;<router-link to="/Contact" class="fst-italic text-decoration-none">{{ $t('verifyEmailPage.error.description.link') }}</router-link>&nbsp;{{ $t('verifyEmailPage.error.description.text2') }}</small>
                <small v-else-if="$t('verifyEmailPage.error.description.text') == 'link'" class="fs-5 d-block justify-content-center text-center error-text">{{ $t('verifyEmailPage.error.description.text') }}&nbsp;<router-link to="/Contact" class="fst-italic text-decoration-none">{{ $t('verifyEmailPage.error.description.link') }}</router-link>&nbsp;{{ $t('verifyEmailPage.error.description.text2') }}</small>
            </section>
        </div>
        <div class="text-center loading-section">
            <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
                <span class="visually-hidden">{{ $t('loadingText') }}</span>
            </div>
        </div>
    </main>
    <FooterPage />
</div>
</template>

<script>
import $ from "jquery";
import {
    verifyEmail,
    resendEmailVerification,
    updateCart,
    addToCart
} from "@/helpers/api";

export default {
    name: "VerifyEmail",
    data() {
        return {
            token: window.localStorage.getItem('token'),
        };
    },
    methods: {
        async resend() {
            const code = this.$route.query.code;
            if (code) {
                const {
                    success
                } = await resendEmailVerification(code);
                if (success == false) {
                    $('.error-section').fadeOut(() => {
                        $('.error-text').html(this.$t('verifyEmailPage.error.description.text') + "&nbsp;");

                        const loginLink = $('<br><a>')
                            .attr('href', '/Contact')
                            .attr('class', 'fst-italic text-decoration-none')
                            .text(this.$t('verifyEmailPage.error.description.link'));

                        $('.error-text').append(loginLink);
                        $('.error-text').append("&nbsp;" + this.$t('verifyEmailPage.error.description.text2'));

                        $('.error-section').fadeIn();
                    });
                } else if (success == true) {
                    $('.success-header').html(this.$t('verifyEmailPage.verificationResend[0]'));
                    $('.success-text').html(this.$t('verifyEmailPage.verificationResend[1]'));
                    $('.error-section').fadeOut(function () {
                        $('.success-section').fadeIn();
                    });
                }
            } else {
                this.$router.push('/login');
            }
        },
    },
    async created() {
        $('.error-section').show();

        const code = this.$route.query.code;
        if (code) {
            const {
                success,
                response
            } = await verifyEmail(code);
            if (success == false) {
                if (response.data.error == "Verification code has expired. Please generate a new code") {
                    $('.error-text').html(this.$t('verifyEmailPage.error.errorText[0]'));

                    const loginLink = $('<br><a>')
                        .attr('href', '#/login')
                        .attr('class', 'btn btn-primary px-3 me-md-2 mt-3')
                        .text(this.$t('verifyEmailPage.error.errorText[1]'))
                        .on('click', (event) => {
                            event.preventDefault();
                            this.resend();
                        });

                    $('.error-text').append(loginLink);
                    $('.loading-section').fadeOut(function () {
                        $('.error-section').fadeIn();
                    });
                } else {
                    this.$router.push('/login');
                }
            } else if (success == true) {
                var data = response.data.data;
                var cart = data.cart;
                var token = data.token;
                window.localStorage.setItem('token', token);
                window.localStorage.setItem('userId', data.user.id);
                window.localStorage.setItem('email', data.user.email);
                var expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 2);
                const cartData = JSON.parse(localStorage.getItem('cart') || '[]');
                if (cartData.length === 0 && cart.length > 0) {
                    $('.btn-loading').fadeOut(function () {
                        $('.login-button-text').fadeIn();
                    });
                    if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                        var newCartItem = {
                            productId: 1,
                            cartQuantity: cart[0].quantity,
                            payorder_id: cart[0].payorder_id
                        };
                        cartData.push(newCartItem);
                        this.$store.dispatch('changeCartItem', {
                            cart: cartData
                        });
                        window.localStorage.setItem('cart', JSON.stringify(cartData));
                    }
                } else if (cartData.length > 0 && (cartData[0].cartQuantity == 0 || cartData[0].cartQuantity == null || cartData[0].cartQuantity == "")) {
                    $('.btn-loading').fadeOut(function () {
                        $('.login-button-text').fadeIn();
                    });
                    if (cart.length > 0) {
                        if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                            cartData[0].cartQuantity = cart[0].quantity;
                            cartData[0].payorder_id = cart[0].payorder_id;
                            this.$store.dispatch('changeCartItem', {
                                cart: cartData
                            });
                            window.localStorage.setItem('cart', JSON.stringify(cartData));
                        }
                    }
                } else if (cartData.length > 0 && cartData[0].cartQuantity > 0) {
                    if (cart) {
                        if (cart.length > 0) {
                            if (cart[0].quantity > 0 && cart[0].quantity != null && cart[0].quantity != "") {
                                if (cartData[0].cartQuantity > cart[0].quantity) {
                                    var operation = true;
                                    if ((parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity) > 0)) {
                                        operation = true;
                                    } else if ((parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity) < 0)) {
                                        operation = false;
                                    }
                                    var updateCartQuantity = parseInt(cartData[0].cartQuantity) - parseInt(cart[0].quantity);
                                    const {
                                        success: cartSuccess
                                    } = await updateCart(token, 1, updateCartQuantity, cart[0].payorder_id, operation);
                                    if (cartSuccess) {
                                        cartData[0].payorder_id = cart[0].payorder_id;
                                        window.localStorage.setItem('cart', JSON.stringify(cartData));
                                    }
                                } else {
                                    cartData[0].cartQuantity = cart[0].quantity;
                                    cartData[0].payorder_id = cart[0].payorder_id;
                                    this.$store.dispatch('changeCartItem', {
                                        cart: cartData
                                    });
                                    window.localStorage.setItem('cart', JSON.stringify(cartData));
                                }
                            }
                        } else {
                            await addToCart(token, 1, parseInt(cartData[0].cartQuantity), cartData[0].payorder_id, true);
                        }
                    }
                }

                window.localStorage.setItem('expirationDate', expirationDate.getTime());
                var userName = success ? (data.user.first_name && data.user.last_name ? `${data.user.first_name} ${data.user.last_name}` : data.user.first_name) : '';
                this.$store.dispatch('login', {
                    token: token,
                    userId: data.user.id,
                    username: userName,
                    expirationdate: expirationDate.getTime()
                });

                $('.loading-section').fadeOut(function () {
                    $('.success-section').fadeIn();
                });

                setTimeout(function (scope) {
                    if (String(scope.$route.path).toLowerCase() === '/verify-email') {
                        if (scope.redirectUrl != null) {
                            scope.$router.push(scope.redirectUrl);
                        } else {
                            scope.$router.push('/Account/Orders');
                        }
                    }
                }, 5000, this);
            }
        } else {
            this.$router.push('/login');
        }
    }
};
</script>

<style scoped src="../assets/css/verify-email.css" />
